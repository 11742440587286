import { withErrorBoundary, Formatter } from 'shared'
import { SmartButtonStub } from 'ui/smart-buttons'
import { LogoImage } from 'customizations'
import { User } from 'api'
import GooglePlacesAutocomplete from "react-google-places-autocomplete";
import 'react-phone-number-input/style.css';
import Input from 'react-phone-number-input/input';

class SignupPage extends Component

  state: { user: { email: '', phone: '', first_name: '', last_name: '', address: '' , user_role_id:'', nonce:'' } , settings:{} , card:{}}

  componentDidMount: ->
    @loadData()
    @parseUTM()


    App.signOut redirectPath: '/app#signup' if User.signedIn && User.regular

  loadData: ->
    @setState settings: await Backend.get('/signup_settings')
    @setupSquarePayment()


  parseUTM: ->
    params = application.queryParams
    if params && params.has('utm_source')
      @setState user: { @state.user..., utm_source: params.get('utm_source') }

  setupSquarePayment: ->
    if @state.settings.require_payment
      squarePayment = window.Square.payments(application.squareApplicationId, null)
      card = await squarePayment.card()
      @setState card: card
      await card.attach('#card-container')


  render: ->
    settings = @state.settings || {}

    pageTitle = settings.page_title
    backgroundColor = settings.color ? '#ccc'
    headImage = settings.head_image_url
    asideImage1 = settings.aside_image_1_url
    asideImage2 = settings.aside_image_2_url

    <div className="Login Signup">
      <div className="left d-none d-sm-block">
        <div className="left-overlay"></div>
        <p className="login-copyright">{application.assets.get('copyright')}</p>
      </div>
      <div className="right">
        <img src={LogoImage} className='logo-image mb-2' />
        <section className="form">
          <h3 className="mb-3 d-none">Sign Up</h3>

          { if @state.success then @renderSuccess() else @renderForm() }

          {
            unless App.isBigSky
              <p className="links text-center my-2">
                <Link to="/home?openSignIn=true" className="smart-button btn inverted primary">
                  <span className="smart-button-text">Return to Sign In</span>
                </Link>
              </p>
          }
          {
            <p className="links text-center my-2">
              <a href="/u/account-deletion" className="smart-button btn inverted primary">
                <span className="smart-button-text">Request Account Deletion</span>
              </a>
            </p>
          }
        </section>
      </div>
    </div>

  renderSuccess: ->
    <p className="alert alert-success text-center">
      {
        if App.isBigSky
          "Thank you for registering! Please check your email for additional welcome information and credentials."
        else
          "Thank you for registering!  Please Sign In to activate your account."
      }
    </p>

  renderForm: ->
    settings = @state.settings || {}

    <form className="signup--form" onSubmit={@submit}>
      <p dangerouslySetInnerHTML={ __html: Formatter.simpleFormat(settings.welcome_text) }></p>
      {
        if @state.errors
          for error in @state.errors
            <p key={error} className="alert alert-danger alert-mini text-center">{error}</p>
      }
      <div className="row">
        <div className="col-sm-6">
          <div className="form-group">
            <label htmlFor="signup--first-name">First Name</label>
            <input type="text" className="form-control" id="signup--first-name" name="first_name" value={@state.first_name} onChange={@inputChanged} />
          </div>
        </div>
        <div className="col-sm-6">
          <div className="form-group">
            <label htmlFor="signup--last-name">Last Name</label>
            <input type="text" className="form-control" id="signup--last-name" name="last_name" value={@state.last_name} onChange={@inputChanged} />
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-sm-6">
          <div className="form-group">
            <label htmlFor="signup--email">Email Address</label>
            <input type="email" className="form-control" id="signup--email" name="email" value={@state.email} onChange={@inputChanged} />
          </div>
        </div>
        <div className="col-sm-6">
          <div className="form-group">
            <label htmlFor="signup--phone">Phone Number</label>
            <Input
              placeholder="Enter phone number"
              type="tel"
              country="US"
              international
              withCountryCallingCode
              maxLength={15}
              className="form-control"
              id="signup--phone"
              name="phone"
              value={@state.phone}
              onChange={@inputChangednew}
            />
          </div>
        </div>
      </div>
      {
        if (!settings.hide_address)
          <div className="row">
            <div className="col-sm-12">
              <div className="form-group">
                <label htmlFor="signup--address">{if App.isBigSky then 'Zip Code' else 'Address'}</label>
                <GooglePlacesAutocomplete
                  apiKey={App.googleApiKey}
                  placeholder= ''
                  onSelect={(result) =>
                    { description, place_id } = result

                    @setState user: { @state.user..., "address": description }, errors: null
                  }
                />
              </div>
            </div>
          </div>
      }
      <div className="row">
          <div className="col-sm-12">
            <div className="form-group">
              <label htmlFor="signup--phone">Select Your Account Type</label>
              <select className='form-control' name="user_role_id" value={@state.user_role_id} onChange={@inputChanged}>
                <option value=''  >Select any</option>
                { settings.user_roles && settings.user_roles.map((role) =>
                  <option value={role.id} key={role.id}>{role.name}</option>
                )}
              </select>
            </div>
          </div>
      </div>
      {
        if (settings.require_payment)
          <div className="row">
              <div className="col-sm-12">
                <div className="form-group">
                  <label htmlFor="signup--phone">Payment Details</label>
                  <div id="card-container"></div>
                </div>
              </div>
          </div>
      }
      <div className="form-actions text-center">
        <SmartButtonStub text="Sign Up" buttonType="submit" className="inverted primary" spinning={@state.submitting} />
      </div>
    </form>


  inputChanged: (e) =>
    { name, value } = e.target
    @setState user: { @state.user..., "#{name}": value }, errors: null

  inputChangednew: (e) =>
    @setState user: { @state.user..., "phone": e }, errors: null


  roleSelected: (e)=>

  selectedRoleId: (e)=>


  submit: (e) =>
    addressVal = document.getElementsByClassName("google-places-autocomplete__input")[0]?.value;
    this.state.user.address = addressVal
    @setState submitting: true
    e.preventDefault()

    if @state.settings.require_payment
      result = await this.state.card.tokenize()
      if result.status is 'OK'
        @setState user: { @state.user..., "nonce": result.token }, errors: null

    try
      res = await Backend.post '/users', user: @state.user
      @setState errors: null, success: true
    catch xhr
      if xhr.status >= 400 && xhr.status <= 499
        @setState errors: xhr.responseJSON.errors
    finally
      @setState submitting: false

export default withErrorBoundary(SignupPage)

